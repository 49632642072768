export default [
  {
    key: "increasement",
    sortable: true,
    sortField: "id",
    label: "#",
    thClass: "w-50px",
  },
  {
    key: "nameEn",
    sortable: true,
    sortField: "nameEn",
    label: "field.nameEn",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "nameKm",
    sortable: true,
    sortField: "nameKm",
    label: "field.nameKm",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "availablePost",
    sortable: true,
    sortField: "availablePost",
    label: "field.availablePost",
    stickyColumn: true,
    variant: "light",
  },
  {
    key: "sequenceOrder",
    sortable: true,
    sortField: "sequenceOrder",
    label: "field.sequenceOrder",
  },
  {
    key: "companyNameEn",
    sortable: true,
    sortField: "companyId",
    label: "field.companyNameEn",
  },
  {
    key: "companyNameKm",
    sortable: true,
    sortField: "companyId",
    label: "field.companyNameKm",
  },
  {
    key: "departmentNameEn",
    sortable: true,
    sortField: "departmentId",
    label: "field.departmentNameEn",
  },
  {
    key: "departmentNameKm",
    sortable: true,
    sortField: "departmentId",
    label: "field.departmentNameKm",
  },
  {
    key: "status",
    label: "field.status",
  },
  {
    key: "createdAt",
    sortable: true,
    sortField: "id",
    label: "field.createdDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "createdBy",
    label: "field.createdBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "updatedAt",
    sortable: true,
    sortField: "updatedAt",
    label: "field.updatedDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "updatedBy",
    label: "field.updatedBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "actions",
    label: "field.action",
  },
];
